import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
//data
const getData = graphql`
  {
    site {
      siteMetadata {
        siteTitle
        siteUrl
      }
    }
    default: contentfulConfig {
      metaDsc {
        metaDsc
      }
      metaImg {
        file {
          url
        }
      }
    }
  }
`
const SEO = ({ title, img, dsc, slug }) => {
  const data = useStaticQuery(getData)
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'fr' }}
        title={
          title
            ? `${data.site.siteMetadata.siteTitle} | ${title}`
            : data.site.siteMetadata.siteTitle
        }
        defer={false}
      >
        <link rel='shortcut icon' href='/favicon.png' type='image/png' />

        <meta
          name='description'
          content={dsc || data.default.metaDsc.metaDsc}
        />
        <meta
          name='image'
          content={
            img
              ? `${data.site.siteMetadata.siteUrl}${img}`
              : `https:${data.default.metaImg.file.url}`
          }
        />
        {/* facebook cards */}
        <meta
          property='og:url'
          content={
            slug
              ? `${data.site.siteMetadata.siteUrl}${slug}`
              : `${data.site.siteMetadata.siteUrl}`
          }
        />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={data.site.siteMetadata.siteTitle} />
        <meta
          property='og:description'
          content={dsc || data.default.metaDsc.metaDsc}
        />
        <meta
          property='og:image'
          content={
            img
              ? `${data.site.siteMetadata.siteUrl}${img}`
              : `https:${data.default.metaImg.file.url}`
          }
        />
        <meta property='og:image:width' content='400' />
        <meta property='og:image:height' content='300' />
        {/* twitter card */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:creator'
          content={data.site.siteMetadata.siteTitle}
        />
        <meta name='twitter:title' content={data.site.siteMetadata.siteTitle} />
        <meta
          name='twitter:description'
          content={dsc || data.default.metaDsc.metaDsc}
        />
        <meta
          name='twitter:image'
          content={
            img
              ? `${data.site.siteMetadata.siteUrl}${img}`
              : `https:${data.default.metaImg.file.url}`
          }
        />
      </Helmet>
    </>
  )
}

export default SEO
